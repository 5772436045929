import { Navigate, RouteObject } from 'react-router-dom';

export const EditTemplateRoutes: RouteObject[] = [
  {
    path: 'template-studio/edit/:templateId',
    lazy: async () => {
      const { EditTemplateLayout } = await import('./layout');
      return { element: <EditTemplateLayout /> };
    },
    children: [
      {
        index: true,
        element: <Navigate to="configuration" replace />,
      },
      {
        path: 'configuration',
        lazy: async () => {
          const { ConfigurationPage } = await import('./configuration/page');
          return { element: <ConfigurationPage /> };
        },
      },
      {
        path: 'editor',
        lazy: async () => {
          const { EditorPage } = await import('./editor/page');
          return { element: <EditorPage /> };
        },
      },
      {
        path: 'review',
        lazy: async () => {
          const { ReviewPage } = await import('./review/page');
          return { element: <ReviewPage /> };
        },
      },
    ],
  },
];
