import { Navigate, RouteObject } from 'react-router-dom';

import { EditorRoutes } from './editor/routes';
import { SubmissionRoutes } from './submission/routes';

export const PDFRoutes: RouteObject = {
  path: 'pdf',
  children: [
    {
      index: true,
      lazy: async () => {
        return { element: <Navigate to="editor" replace /> };
      },
    },
    {
      path: 'documents',
      lazy: async () => {
        const { DocumentsPage } = await import('./documents/page');
        return { element: <DocumentsPage /> };
      },
    },
    EditorRoutes,
    SubmissionRoutes,
  ],
};
