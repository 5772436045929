import { UserType } from '@/lib/user/type';

export enum DashboardType {
  Admin = 'admin',
  Provider = 'provider',
  OpsAnalyst = 'ops-analyst',
  Superadmin = 'superadmin',
}

export function getDashboardType(userType: UserType): DashboardType {
  switch (userType) {
    case UserType.Admin:
      return DashboardType.Admin;

    case UserType.Provider:
      return DashboardType.Provider;

    case UserType.OpsAnalyst:
    case UserType.ExternalOpsAnalyst:
      return DashboardType.OpsAnalyst;

    case UserType.Superadmin:
    case UserType.ExternalSupervisor:
      return DashboardType.Superadmin;
  }
}
