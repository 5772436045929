import { useQuery } from '@tanstack/react-query';

import { fetchClient } from '@/lib/api/client';
import { extensionFromFileName } from '@/lib/file/utils';

type SubmissionItem = {
  id: string;
  name: string;
  isClear: boolean;
} & (
  | {
      type: 'annotation-pdf';
    }
  | {
      type: 'document';
      fileType: 'image' | 'pdf' | null;
    }
);

async function getEditablePdfSubmissionItems(
  enrollmentId: string,
): Promise<SubmissionItem[]> {
  const result = await fetchClient.GET(
    '/api/v1/payer-enrollment/enrollment-pdf/',
    {
      params: {
        query: {
          enrollment_request: enrollmentId,
        },
      },
    },
  );

  return (
    result.data?.results?.map((item) => ({
      id: item.id,
      name: item.pdf_template.template_name,
      type: 'annotation-pdf',
      isClear: item.oa_saved,
    })) ?? []
  );
}

function getFileTypeFromExtension(type: string): 'pdf' | 'image' | null {
  switch (type) {
    case 'pdf':
      return 'pdf';

    case 'png':
    case 'jpeg':
    case 'jpg':
    case 'webp':
      return 'image';
  }

  return null;
}

async function getDocumentSubmissionItems(
  enrollmentId: string,
): Promise<SubmissionItem[]> {
  const result = await fetchClient.GET(
    '/api/v1/payer-enrollment/payer-enrollment-document-listing/',
    {
      params: {
        query: {
          enrollment_request: enrollmentId,
        },
      },
    },
  );

  return (
    result.data?.results
      ?.filter((item) => item.result === 'CLEAR')
      ?.map((item) => {
        const extension = item.document_url
          ? extensionFromFileName(item.document_url)
          : null;

        return {
          id: item.id,
          name: item.document_type,
          isClear: true,
          type: 'document',
          fileType: extension ? getFileTypeFromExtension(extension) : null,
        };
      }) ?? []
  );
}

export function useGetSubmissionItemListQuery({
  enrollmentId,
}: {
  enrollmentId: string;
}) {
  return useQuery({
    queryKey: ['get', 'enrollment-submission-items', enrollmentId],
    queryFn: async (): Promise<SubmissionItem[]> => {
      return [
        ...(await getEditablePdfSubmissionItems(enrollmentId)),
        ...(await getDocumentSubmissionItems(enrollmentId)),
      ];
    },
  });
}

export function useGetSubmissionItemQuery({
  enrollmentId,
  itemId,
}: {
  enrollmentId: string;
  itemId: string;
}) {
  const { data, isLoading } = useGetSubmissionItemListQuery({
    enrollmentId: enrollmentId,
  });

  return {
    data: data?.find((item) => item.id === itemId),
    isLoading,
  };
}
