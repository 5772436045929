import { Navigate, RouteObject } from 'react-router-dom';

import ProtectedRoute from '@/components/auth/protected-route';

import { DashboardType } from '@/lib/dashboard/type';

export const OnboardingRoutes: RouteObject[] = [
  {
    path: 'onboarding/admin',
    lazy: async () => {
      const { AdminOnboardingLayout } = await import('./admin/layout');
      return {
        element: (
          <ProtectedRoute dashboardType={DashboardType.Admin}>
            <AdminOnboardingLayout />
          </ProtectedRoute>
        ),
      };
    },
    children: [
      {
        index: true,
        lazy: async () => {
          const { AdminOnboardingPage } = await import('./admin/page');
          return { element: <AdminOnboardingPage /> };
        },
      },
      {
        path: 'account-details',
        lazy: async () => {
          const { AccountDetailsPage } = await import(
            './admin/account-details/page'
          );
          return { element: <AccountDetailsPage /> };
        },
      },
      {
        path: 'tax-entities',
        lazy: async () => {
          const { TaxEntitiesPage } = await import('./admin/tax-entities/page');
          return { element: <TaxEntitiesPage /> };
        },
      },
      {
        path: 'tax-entities/new',
        lazy: async () => {
          const { OnboardingNewTaxEntitiesPage } = await import(
            './admin/tax-entities/new/page'
          );
          return {
            element: <OnboardingNewTaxEntitiesPage />,
          };
        },
      },
      {
        path: 'tax-entities/:taxEntityId',
        lazy: async () => {
          const { OnboardingTaxEntitiesLayout } = await import(
            './admin/tax-entities/$taxEntityId/layout'
          );
          return {
            element: <OnboardingTaxEntitiesLayout />,
          };
        },
        children: [
          {
            index: true,
            element: <Navigate to="entity-info" replace />,
          },
          {
            path: 'entity-info',
            lazy: async () => {
              const { EntityInfoPage } = await import(
                './admin/tax-entities/$taxEntityId/entity-info/page'
              );
              return { element: <EntityInfoPage /> };
            },
          },
          {
            path: 'address',
            lazy: async () => {
              const { AddressPage } = await import(
                './admin/tax-entities/$taxEntityId/address/page'
              );
              return { element: <AddressPage /> };
            },
          },
          {
            path: 'financial-info',
            lazy: async () => {
              const { FinancialInfoPage } = await import(
                './admin/tax-entities/$taxEntityId/financial-info/page'
              );
              return { element: <FinancialInfoPage /> };
            },
          },
          {
            path: 'operational-info',
            lazy: async () => {
              const { OperationalInfoPage } = await import(
                './admin/tax-entities/$taxEntityId/operational-info/page'
              );
              return { element: <OperationalInfoPage /> };
            },
          },
          {
            path: 'documents',
            lazy: async () => {
              const { DocumentsPage } = await import(
                './admin/tax-entities/$taxEntityId/documents/page'
              );
              return { element: <DocumentsPage /> };
            },
          },
          {
            path: 'practice-locations',
            lazy: async () => {
              const { PracticeLocationsPage } = await import(
                './admin/tax-entities/$taxEntityId/practice-locations/page'
              );
              return { element: <PracticeLocationsPage /> };
            },
          },
        ],
      },
      {
        path: 'invite-providers',
        lazy: async () => {
          const { InviteProvidersPage } = await import(
            './admin/invite-providers/page'
          );
          return { element: <InviteProvidersPage /> };
        },
      },
    ],
  },
  {
    path: 'onboarding/provider',
    lazy: async () => {
      const { ProviderOnboardingLayout } = await import('./provider/layout');
      return {
        element: (
          <ProtectedRoute dashboardType={DashboardType.Provider}>
            <ProviderOnboardingLayout />
          </ProtectedRoute>
        ),
      };
    },
    children: [
      {
        index: true,
        element: <Navigate to="caqh-import" replace />,
      },
      {
        path: 'caqh-import',
        lazy: async () => {
          const { CAQHImportPage } = await import(
            './provider/caqh-import/page'
          );
          return { element: <CAQHImportPage /> };
        },
      },
      {
        path: 'profile',
        lazy: async () => {
          const { OnboardingProfileLayout } = await import(
            './provider/profile/layout'
          );
          return { element: <OnboardingProfileLayout /> };
        },
        children: [
          {
            index: true,
            element: <Navigate to="personal-info" replace />,
          },
          {
            path: 'personal-info',
            lazy: async () => {
              const { PersonalInfoPage } = await import(
                './provider/profile/personal-info/page'
              );
              return { element: <PersonalInfoPage /> };
            },
          },
          {
            path: 'professional-ids',
            lazy: async () => {
              const { ProfessionalIDsPage } = await import(
                './provider/profile/professional-ids/page'
              );
              return { element: <ProfessionalIDsPage /> };
            },
          },
          {
            path: 'education',
            lazy: async () => {
              const { EducationPage } = await import(
                './provider/profile/education/page'
              );
              return { element: <EducationPage /> };
            },
          },
          {
            path: 'liability-insurance',
            lazy: async () => {
              const { LiabilityInsurancePage } = await import(
                './provider/profile/liability-insurance/page'
              );
              return { element: <LiabilityInsurancePage /> };
            },
          },
          {
            path: 'specialties',
            lazy: async () => {
              const { SpecialtiesPage } = await import(
                './provider/profile/specialties/page'
              );
              return { element: <SpecialtiesPage /> };
            },
          },
          {
            path: 'employment',
            lazy: async () => {
              const { EmploymentPage } = await import(
                './provider/profile/employment/page'
              );
              return { element: <EmploymentPage /> };
            },
          },
          {
            path: 'documents',
            lazy: async () => {
              const { DocumentsPage } = await import(
                './provider/profile/documents/page'
              );
              return { element: <DocumentsPage /> };
            },
          },
          {
            path: 'disclosures',
            lazy: async () => {
              const { DisclosuresPage } = await import(
                './provider/profile/disclosures/page'
              );
              return { element: <DisclosuresPage /> };
            },
          },
        ],
      },
    ],
  },
];
