import { Skeleton } from 'antd';
import { Navigate, RouteObject, useParams } from 'react-router-dom';

import { $api } from '@/lib/api/client';
import { missingPathParam } from '@/lib/route-errors';

function NavigateToFirstItem() {
  const { enrollmentId } = useParams();
  if (!enrollmentId) {
    missingPathParam('enrollmentId');
  }

  const { data } = $api.useQuery(
    'get',
    '/api/v1/payer-enrollment/enrollment-pdf/',
    {
      params: {
        query: {
          enrollment_request: enrollmentId,
        },
      },
    },
  );

  if (!data) {
    return <Skeleton />;
  }

  const firstItem = data.results[0];
  if (!firstItem) {
    return null;
  }

  return <Navigate to={firstItem.id} replace />;
}

export const EditorRoutes: RouteObject = {
  path: 'editor',
  lazy: async () => {
    const { PDFEditorLayout } = await import('./layout');
    return { element: <PDFEditorLayout /> };
  },
  children: [
    {
      index: true,
      lazy: async () => {
        return { element: <NavigateToFirstItem /> };
      },
    },
    {
      path: ':formId',
      lazy: async () => {
        const { FormEditorPage } = await import('./$formId/page');
        return { element: <FormEditorPage /> };
      },
    },
  ],
};
