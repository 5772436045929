import { RouteObject } from 'react-router-dom';

import { ClientDetailsRoutes } from './$clientId/routes';

export const ClientsRoutes: RouteObject = {
  path: 'clients',
  children: [
    {
      index: true,
      lazy: async () => {
        const { ClientListPage } = await import('./page');
        return { element: <ClientListPage /> };
      },
    },
    ClientDetailsRoutes,
  ],
};
