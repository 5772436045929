import { Navigate, RouteObject } from 'react-router-dom';

import { CreateTemplateRoutes } from './create/routes';
import { EditTemplateRoutes } from './edit/$templateId/routes';

export const TemplateStudioRoutes: RouteObject[] = [
  {
    path: 'template-studio',
    lazy: async () => {
      const { TemplateStudioLayout } = await import('./layout');
      return { element: <TemplateStudioLayout /> };
    },
    children: [
      {
        index: true,
        element: <Navigate to="health-plan" replace />,
      },
      {
        path: 'health-plan',
        lazy: async () => {
          const { HealthPlan } = await import('./health-plan/page');
          return { element: <HealthPlan /> };
        },
      },
      {
        path: 'licensing-application',
        lazy: async () => {
          const { LicensingApplication } = await import(
            './licensing-application/page'
          );
          return { element: <LicensingApplication /> };
        },
      },
    ],
  },
  ...CreateTemplateRoutes,
  ...EditTemplateRoutes,
];
