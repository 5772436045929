import { Navigate, RouteObject } from 'react-router-dom';

export const ClientDetailsRoutes: RouteObject = {
  path: ':clientId',
  lazy: async () => {
    const { ClientDetailsLayout } = await import('./layout');
    return { element: <ClientDetailsLayout /> };
  },
  children: [
    {
      index: true,
      element: <Navigate to="overview" replace />,
    },
    {
      path: 'overview',
      lazy: async () => {
        const { OverviewPage } = await import('./overview/page');
        return { element: <OverviewPage /> };
      },
    },
    {
      path: 'product-configurations',
      lazy: async () => {
        const { ProductConfigurationsPage } = await import(
          './product-configurations/page'
        );
        return { element: <ProductConfigurationsPage /> };
      },
    },
  ],
};
