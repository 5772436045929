import { Navigate, RouteObject } from 'react-router-dom';

export const PortalAutoRoutes: RouteObject = {
  path: 'portal-auto',
  children: [
    {
      element: <Navigate to="documents" replace />,
    },
    {
      path: 'documents',
      lazy: async () => {
        const { DocumentsPage } = await import('./documents/page');
        return { element: <DocumentsPage /> };
      },
    },
    {
      path: 'submission',
      lazy: async () => {
        const { SubmissionPage } = await import('./submission/page');
        return { element: <SubmissionPage /> };
      },
    },
  ],
};
