import { Navigate, RouteObject } from 'react-router-dom';

export const EnrollmentsRoutes: RouteObject[] = [
  {
    path: 'enrollments',
    lazy: async () => {
      const { SuperAdminEnrollmentsLayout } = await import(
        '@/pages/superadmin/enrollments/layout'
      );
      return { element: <SuperAdminEnrollmentsLayout /> };
    },
    children: [
      {
        index: true,
        element: <Navigate to="requests" replace />,
      },
      {
        path: 'requests',
        lazy: async () => {
          const { RequestsPage } = await import('./requests/page');
          return { element: <RequestsPage /> };
        },
      },
    ],
  },
];
