import { Navigate, RouteObject } from 'react-router-dom';

export const EnrollmentsRoutes: RouteObject[] = [
  {
    path: 'enrollments',
    lazy: async () => {
      const { AdminPayerEnrollmentsLayout } = await import(
        '@/pages/admin/enrollments/layout'
      );
      return { element: <AdminPayerEnrollmentsLayout /> };
    },
    children: [
      {
        index: true,
        element: <Navigate to="requests" replace />,
      },
      {
        path: 'requests',
        lazy: async () => {
          const { RequestsPage } = await import('./requests/page');
          return { element: <RequestsPage /> };
        },
      },

      {
        path: 'active-enrollments',
        lazy: async () => {
          const { ActiveEnrollmentsPage } = await import(
            './active-enrollments/page'
          );
          return { element: <ActiveEnrollmentsPage /> };
        },
      },
    ],
  },
];
