export enum UserType {
  Admin = 'admin',
  Provider = 'provider',
  OpsAnalyst = 'ops-analyst',
  ExternalOpsAnalyst = 'external-ops-analyst',
  Superadmin = 'superadmin',
  ExternalSupervisor = 'external-supervisor',
}

type DtoType =
  | 'client_admin'
  | 'oa'
  | 'external_oa'
  | 'provider'
  | 'superadmin'
  | 'external_supervisor';

type Properties = {
  title: string;
  dtoModel: DtoType;
};

export const userTypeConfig: Record<UserType, Properties> = {
  [UserType.Admin]: {
    title: 'Admin',
    dtoModel: 'client_admin',
  },
  [UserType.Provider]: {
    title: 'Provider',
    dtoModel: 'provider',
  },
  [UserType.OpsAnalyst]: {
    title: 'Operations Analyst',
    dtoModel: 'oa',
  },
  [UserType.ExternalOpsAnalyst]: {
    title: 'External Operations Analyst',
    dtoModel: 'external_oa',
  },
  [UserType.Superadmin]: {
    title: 'Superadmin',
    dtoModel: 'superadmin',
  },
  [UserType.ExternalSupervisor]: {
    title: 'External Supervisor',
    dtoModel: 'external_supervisor',
  },
};

export function dtoToUserType(dto: string | null | undefined): UserType | null {
  switch (dto) {
    case 'client_admin':
      return UserType.Admin;

    case 'provider':
      return UserType.Provider;

    case 'oa':
      return UserType.OpsAnalyst;

    case 'external_oa':
      return UserType.ExternalOpsAnalyst;

    case 'superadmin':
      return UserType.Superadmin;

    case 'external_supervisor':
      return UserType.ExternalSupervisor;
  }

  return null;
}
