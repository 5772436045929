import { Navigate, RouteObject } from 'react-router-dom';

export const PortalSemiAutoRoutes: RouteObject = {
  path: 'portal-semi-auto',
  children: [
    {
      element: <Navigate to="documents" replace />,
    },
    {
      path: 'documents',
      lazy: async () => {
        const { DocumentsPage } = await import('./documents/page');
        return { element: <DocumentsPage /> };
      },
    },
  ],
};
