import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { Button, Spin, theme, Typography } from 'antd';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

import CommonLayout from '@/components/layout/common-layout';

import { useSessionStore } from '@/lib/auth/session-store';
import { DashboardType, getDashboardType } from '@/lib/dashboard/type';
import detectVersionChange from '@/lib/error/detect-version-change';
import { exhaustiveSwitchGuard } from '@/lib/exhaustive-switch-guard';

type ErrorData = {
  title: string;
  subtitle: string;
  description: string;
  errorToReport?: unknown;
};

function useRouteErrorData(): ErrorData | null {
  const error = useRouteError();

  if (!isRouteErrorResponse(error)) {
    if (import.meta.env.DEV) {
      return {
        title: 'Error',
        subtitle: 'An unhandled exception occurred',
        description: 'Please look at the console for more details',
      };
    }

    const isVersionChange = detectVersionChange(error);
    if (isVersionChange) {
      // we don't want to show the error page while reloading the page
      return null;
    }

    return {
      title: 'Oops',
      subtitle: 'Something went wrong',
      description: `We're experiencing a temporary issue. Please retry in a moment.`,
      errorToReport: error,
    };
  }

  if (error.status === 404) {
    return {
      title: '404',
      subtitle: 'Page not found',
      description: 'The page you are looking for does not exist.',
    };
  }

  return {
    title: error.status.toString(),
    subtitle: error.statusText,
    description: 'Oops! An unexpected error occurred.',
  };
}

function useGetDashboardLink(): string | null {
  const userType = useSessionStore((state) => state.user?.type);
  if (!userType) {
    return null;
  }

  const dashboardType = getDashboardType(userType);

  switch (dashboardType) {
    case DashboardType.Admin:
      return '/admin';

    case DashboardType.OpsAnalyst:
      return '/ops-analyst';

    case DashboardType.Provider:
      return '/provider';

    case DashboardType.Superadmin:
      return '/superadmin';
  }

  return exhaustiveSwitchGuard(dashboardType);
}

export default function RootErrorBoundary() {
  const {
    token: { colorTextDescription },
  } = theme.useToken();
  const errorData = useRouteErrorData();
  const dashboardLink = useGetDashboardLink();

  useEffect(() => {
    if (errorData?.errorToReport) {
      Sentry.captureException(errorData.errorToReport);
    }
  }, [errorData]);

  if (!errorData) {
    return <Spin fullscreen />;
  }

  return (
    <CommonLayout>
      <div className="mt-16">
        <div className="flex flex-col items-center justify-start">
          <Typography.Title className="m-0 text-8xl">
            {errorData.title}
          </Typography.Title>
          <Typography.Title level={2} className="m-0 mt-12">
            {errorData.subtitle}
          </Typography.Title>
          <Typography.Text
            className="m-0 mt-2 text-lg"
            style={{ color: colorTextDescription }}
          >
            {errorData.description}
          </Typography.Text>
          {dashboardLink && (
            <Button
              className="m-0 mt-6"
              onClick={() => {
                // Redirect to the dashboard link via hard navigation as that re-fetches
                // all the js modules and resets any local state
                window.location.href = dashboardLink;
              }}
            >
              Back to dashboard
            </Button>
          )}
        </div>
      </div>
    </CommonLayout>
  );
}
