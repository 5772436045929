import { Navigate, RouteObject } from 'react-router-dom';

import { PDFRoutes } from './pdf/routes';
import { PortalAutoRoutes } from './portal-auto/routes';
import { PortalManualRoutes } from './portal-manual/routes';
import { PortalSemiAutoRoutes } from './portal-semi-auto/routes';

export const EnrollmentDetailsRoutes: RouteObject = {
  path: 'enrollments/:enrollmentId',
  lazy: async () => {
    const { EnrollmentDetailsLayout } = await import('./layout');
    return { element: <EnrollmentDetailsLayout /> };
  },
  children: [
    {
      index: true,
      element: <Navigate to="overview" replace />,
    },
    {
      path: 'overview',
      lazy: async () => {
        const { OverviewPage } = await import('./overview/page');
        return { element: <OverviewPage /> };
      },
    },
    PDFRoutes,
    PortalManualRoutes,
    PortalAutoRoutes,
    PortalSemiAutoRoutes,
    {
      path: 'timeline',
      lazy: async () => {
        const { TimelinePage } = await import('./timeline/page');
        return { element: <TimelinePage /> };
      },
    },
  ],
};
