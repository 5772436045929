import { Skeleton } from 'antd';
import { Navigate, RouteObject, useParams } from 'react-router-dom';

import { missingPathParam } from '@/lib/route-errors';

import { useGetSubmissionItemListQuery } from './query';

function NavigateToFirstItem() {
  const { enrollmentId } = useParams();
  if (!enrollmentId) {
    missingPathParam('enrollmentId');
  }

  const { data } = useGetSubmissionItemListQuery({
    enrollmentId: enrollmentId,
  });

  if (!data) {
    return <Skeleton />;
  }

  const firstItem = data[0];
  if (!firstItem) {
    return null;
  }

  return <Navigate to={firstItem.id} replace />;
}

export const SubmissionRoutes: RouteObject = {
  path: 'submission',
  lazy: async () => {
    const { SubmissionLayout } = await import('./layout');
    return { element: <SubmissionLayout /> };
  },
  children: [
    {
      index: true,
      lazy: async () => {
        return { element: <NavigateToFirstItem /> };
      },
    },
    {
      path: ':itemId',
      lazy: async () => {
        const { DocumentReviewPage } = await import('./$itemId/page');
        return { element: <DocumentReviewPage /> };
      },
    },
  ],
};
