import { createBrowserRouter, Navigate } from 'react-router-dom';

import ProtectedRoute from '@/components/auth/protected-route';
import { AdminRoutes } from '@/pages/admin/routes';
import RootErrorBoundary from '@/pages/error';
import RootLayout from '@/pages/layout';
import { OpsAnalystRoutes } from '@/pages/ops-analyst/routes';

import { OnboardingRoutes } from './onboarding/routes';
import { ProviderRoutes } from './provider/routes';
import { SuperadminRoutes } from './superadmin/routes';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <RootErrorBoundary />,
    children: [
      {
        index: true,
        lazy: async () => {
          const { HomePage } = await import('@/pages/page');
          return {
            element: (
              <ProtectedRoute dashboardType="all">
                <HomePage />
              </ProtectedRoute>
            ),
          };
        },
      },
      {
        path: '/sign-in',
        lazy: async () => {
          const { SignInPage } = await import('@/pages/sign-in/page');
          return { element: <SignInPage /> };
        },
      },
      {
        path: '/create-account/admin',
        lazy: async () => {
          const { AdminCreateAccountPage } = await import(
            '@/pages/create-account/admin/page'
          );
          return { element: <AdminCreateAccountPage /> };
        },
      },
      {
        path: '/create-account/invite-expired',
        lazy: async () => {
          const { AdminInviteExpiredPage } = await import(
            '@/pages/create-account/invite-expired/page'
          );
          return { element: <AdminInviteExpiredPage /> };
        },
      },
      {
        path: '/create-account/provider',
        lazy: async () => {
          const { ProviderCreateAccountPage } = await import(
            '@/pages/create-account/provider/page'
          );
          return { element: <ProviderCreateAccountPage /> };
        },
      },
      {
        path: '/create-account/superadmin',
        lazy: async () => {
          const { SuperadminCreateAccountPage } = await import(
            '@/pages/create-account/superadmin/page'
          );
          return { element: <SuperadminCreateAccountPage /> };
        },
      },
      {
        path: '/create-account/ops-analyst',
        lazy: async () => {
          const { OpsAnalystCreateAccountPage } = await import(
            '@/pages/create-account/ops-analyst/page'
          );
          return { element: <OpsAnalystCreateAccountPage /> };
        },
      },
      {
        path: '/forgot-password',
        lazy: async () => {
          const { ForgotPasswordPage } = await import(
            '@/pages/forgot-password/page'
          );
          return { element: <ForgotPasswordPage /> };
        },
      },
      {
        path: '/reset-password',
        lazy: async () => {
          const { ResetPasswordPage } = await import(
            '@/pages/reset-password/page'
          );
          return { element: <ResetPasswordPage /> };
        },
      },
      {
        path: '/account-details',
        // TODO: This if for backwards compatibility, remove it once new client admin onboarding flow is released to production
        element: <Navigate to="/onboarding/admin" />,
      },
      {
        path: '/sign-out',
        lazy: async () => {
          const { SignOutPage } = await import('@/pages/sign-out/page');
          return { element: <SignOutPage /> };
        },
      },
      ...OnboardingRoutes,
      ...AdminRoutes,
      ...OpsAnalystRoutes,
      ...ProviderRoutes,
      ...SuperadminRoutes,
    ],
  },
]);
