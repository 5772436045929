import { Navigate, RouteObject } from 'react-router-dom';

export const CredentialingRoutes: RouteObject = {
  path: 'credentialing',
  children: [
    {
      index: true,
      element: <Navigate to="provider" replace />,
    },
    {
      path: 'provider',
      lazy: async () => {
        const { ProviderCredentialingPage } = await import('./provider/page');
        return { element: <ProviderCredentialingPage /> };
      },
    },
    {
      path: 'facility',
      lazy: async () => {
        const { FacilityCredentialingPage } = await import('./facility/page');
        return { element: <FacilityCredentialingPage /> };
      },
    },
  ],
};
