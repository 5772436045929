import { Navigate, RouteObject } from 'react-router-dom';

import ProtectedRoute from '@/components/auth/protected-route';

import { DashboardType } from '@/lib/dashboard/type';

import { CredentialingRoutes } from './credentialing/routes';
import { EnrollmentsRoutes } from './enrollments/routes';
import { MonitoringRoutes } from './monitoring/routes';

export const OpsAnalystRoutes: RouteObject[] = [
  {
    path: '/ops-analyst',
    lazy: async () => {
      const { OpsAnalystLayout } = await import('./layout');
      return {
        element: (
          <ProtectedRoute dashboardType={DashboardType.OpsAnalyst}>
            <OpsAnalystLayout />
          </ProtectedRoute>
        ),
      };
    },
    children: [
      {
        index: true,
        element: <Navigate to="tasks" replace />,
      },
      {
        path: 'tasks',
        lazy: async () => {
          const { OpsAnalystTasksLayout } = await import('./tasks/layout');
          return { element: <OpsAnalystTasksLayout /> };
        },
        children: [
          {
            index: true,
            element: <Navigate to="provider" replace />,
          },
          {
            path: 'provider',
            lazy: async () => {
              const { ProviderTasksForOpsAnalystPage } = await import(
                './tasks/provider/page'
              );
              return { element: <ProviderTasksForOpsAnalystPage /> };
            },
          },
        ],
      },
      CredentialingRoutes,
      ...EnrollmentsRoutes,
      ...MonitoringRoutes,
      {
        path: 'settings',
        lazy: async () => {
          const { SettingsPage } = await import('./settings/page');
          return { element: <SettingsPage /> };
        },
      },
    ],
  },
];
