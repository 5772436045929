export function fileNameFromUrl(presignedUrl: string): string | null {
  if (!presignedUrl) {
    return null;
  }

  try {
    const url = new URL(presignedUrl);

    // Check for the filename in the response-content-disposition
    const contentDisposition = url.searchParams.get(
      'response-content-disposition',
    );

    if (contentDisposition) {
      const filenameMatch = /filename="?([^"]+)"?/.exec(contentDisposition);
      if (filenameMatch && filenameMatch[1]) {
        return filenameMatch[1];
      }
    }

    // Extract the filename from the pathname
    const pathname = url.pathname;

    const parts = pathname.split('/');
    const lastPart = parts[parts.length - 1];
    const fileName = decodeURIComponent(lastPart);

    return fileName || null;
  } catch (error) {
    console.error('Error parsing URL:', error);
    return null;
  }
}

export function extensionFromFileName(fileName: string): string | null {
  return fileName.split('.').pop()?.toLowerCase() ?? null;
}

export async function downloadFile(
  url: string,
  fileName: string,
): Promise<void> {
  const response = await fetch(url, {
    headers: {
      'Cache-Control': 'no-cache',
    },
  });
  const blob = await response.blob();
  const downloadUrl = window.URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = downloadUrl;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(downloadUrl);
}
