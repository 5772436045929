/**
 * This function is used to exhaustively guard against all cases of a switch
 * statement while making sure that unknown runtime cases are handled as well.
 *
 * Though the compiler will normally check when a switch statement is used for
 * enums without a default case. The problem comes when there is IO data
 * parsing involved. In that case, we want the compiler to warn us when a new
 * item is added to the possible input data, but at the same time, we want to
 * handle the runtime case where an unknown item is passed in.
 *
 * The _switchArg parameter of type never makes sure that this function is
 * never reached at compile time, and the null makes sure that we handle the
 * unknown value at runtime.
 *
 * Only use this in specific IO parsing cases.
 *
 * Usage:
 *
 * export function dtoToTaskType(
 *  dto: components['schemas']['TaskTypeEnum'],
 * ): TaskType | null {
 *   switch (dto) {
 *     case 'INFORMATION_REQUIRED':
 *       return TaskType.InformationRequired;
 *     case 'PROFILE_INCOMPLETE':
 *       return TaskType.IncompleteProfile;
 *     case 'CREDENTIALING_REQUEST':
 *       return TaskType.CredentialingRequest;
 *     case 'EXPIRABLE':
 *       return TaskType.Expirable;
 *     case 'SIGNATURE':
 *       return TaskType.Signature;
 *   }
 *
 *   return exhaustiveSwitchGuard(dto);
 * }
 *
 * Ignore the unreachable code warning.
 *
 * @param _switchArg - The argument passed to the switch statement.
 * @returns null - This function always returns null.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function exhaustiveSwitchGuard(_switchArg: never): null {
  return null;
}
