import { Navigate, RouteObject } from 'react-router-dom';

import ProtectedRoute from '@/components/auth/protected-route';

import { useSessionStore } from '@/lib/auth/session-store';
import { DashboardType } from '@/lib/dashboard/type';
import { UserType } from '@/lib/user/type';

import { ClientsRoutes } from './clients/routes';
import { ConfigurationsRoutes } from './configurations/routes';
import { CredentialingRoutes } from './credentialing/routes';
import { EnrollmentsRoutes } from './enrollments/routes';
import { MonitoringRoutes } from './monitoring/routes';
import { TemplateStudioRoutes } from './template-studio/routes';
import { UsersRoutes } from './users/routes';

function NavigateToFirstItem() {
  const user = useSessionStore((state) => state.user);

  if (!user?.type) {
    return null;
  }

  switch (user.type) {
    case UserType.Superadmin:
      return <Navigate to="/superadmin/clients" replace />;
    case UserType.ExternalSupervisor:
      return <Navigate to="/superadmin/enrollments" replace />;
    default:
      return null;
  }
}

export const SuperadminRoutes: RouteObject[] = [
  {
    path: '/superadmin',
    lazy: async () => {
      const { SuperadminLayout } = await import('./layout');
      return {
        element: (
          <ProtectedRoute dashboardType={DashboardType.Superadmin}>
            <SuperadminLayout />
          </ProtectedRoute>
        ),
      };
    },
    children: [
      {
        index: true,
        lazy: async () => ({
          element: <NavigateToFirstItem />,
        }),
      },
      ClientsRoutes,
      UsersRoutes,
      ConfigurationsRoutes,
      ...TemplateStudioRoutes,
      ...EnrollmentsRoutes,
      CredentialingRoutes,
      MonitoringRoutes,
    ],
  },
];
