import { RouteObject } from 'react-router-dom';

import { EnrollmentDetailsRoutes } from './$enrollmentId/routes';

export const EnrollmentsRoutes: RouteObject[] = [
  {
    path: 'enrollments',
    lazy: async () => {
      const { EnrollmentsPage } = await import('./page');
      return { element: <EnrollmentsPage /> };
    },
  },
  EnrollmentDetailsRoutes,
];
